<template>
    <div class="SetLine">
        <div class="flex-nowrap-space-between mb-20">
            <div class="flex-nowrap-space-around">
                <el-radio-group v-model="searchForm.mode" size="small" @change="setTimeData">
                    <el-radio-button label="0">
                        <span class="type-name">AXB模式</span>
                    </el-radio-button>
                    <el-radio-button label="1">
                        <span class="type-name">回拨模式</span>
                    </el-radio-button>
                </el-radio-group>
                <div class="ml-20">
                   
                </div>
            </div>
            <div>
                <!-- <el-pagination background layout="prev, pager, next" :total="total"  @current-change="handleCurrentChange">
                 </el-pagination> -->
            </div>
        </div>
        <div class="flex-wrap-space-around lineCard-show-box" v-loading="loading" id="scroll" ref="scrollTopList">
            <lineCard 
            v-for="(line,l) in lineList" 
            :key="l"
            :lineData="line"
            :modemaap="modemaap"
            type="show" 
            :status="line.status == 0?'ok':'err'">
                <template v-slot:left='ctx'>
                    <el-button size="medium" round class="button-left button-width" @click="checkView(ctx.data)">查看</el-button>
                </template>
                <template v-slot:right='ctx'>
                    <!-- <el-button size="medium" round class="button-right button-width" v-if="line.status == 0" @click="numbeArssigned(ctx.data)">申请小号</el-button> -->
                    <el-button size="medium" round class="button-right-other button-width" >暂无法申请</el-button>
                </template>
            </lineCard>
        </div>
        <div>
            <div class="show-html-dialog">
                <el-dialog 
                v-model="centerDialogVisible" 
                title="线路其他信息" 
                :close-on-click-modal="false"
                :destroy-on-close="true"
                width="40%">
                    <div v-html="html" class="show-html"></div>
                </el-dialog>
            </div>
            <el-dialog 
            v-model="numbeArssignedDialogVisible" 
            title="申请小号分配 " 
            :close-on-click-modal="false"
            :destroy-on-close="true"
            width="600px">
                <div class="buy-notice">
                    购买须知：申请审核成功后，管理员将为您分配小号，当小号拨通成功后，实时扣取通话费用
                </div>
                <div class="font-size-color">
                    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
                        <el-form-item label="所选线路">
                            <div class="font-size-color">
                                {{ form.amountName }}
                            </div>
                        </el-form-item>
                        <el-form-item label="线路模式">
                            <div class="font-size-color">
                                {{ modemaap[form.mode] }}
                            </div>
                        </el-form-item>
                        <el-form-item label="资费选择" prop="expenses">
                            <div class="select-out-box">
                                <el-select v-model="form.expenses" class="line-el-select" size="small" placeholder="请选择资费">
                                    <el-option
                                    v-for="(item,i) in optionsList"
                                    :key="i"
                                    :label="item.name"
                                    :value="item.name"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                        <el-form-item label="小号分布/数量">
                            <div class="out-num-box">
                                <div style="width:100%;" v-for="(reg,r) in form.region" :key="r">
                                    <div class="flex-nowrap-flex-start" >
                                        <div class="select-out-box-other">
                                            <el-cascader
                                            v-model="reg.name"
                                            size="small"
                                            :options="numberCountList"
                                            @change="handleChange($event,r)"
                                            :props="{ checkStrictly: true ,value:'name',label:'name',children: 'citys'}"
                                            clearable
                                            />
                                        </div>
                                        <div class="ml-10">
                                            <el-input-number
                                                v-model="reg.num"
                                                size="small"
                                                :min="1"
                                                :max="reg.max"
                                                controls-position="right"
                                            />
                                        </div>
                                        <div class="ml-10 add-text" @click="addItem">
                                            新增
                                        </div>
                                        <div class="ml-10 dle-text" v-if="r!=0" @click="dleItem(r)">
                                            删除
                                        </div>
                                    </div>
                                    <div class="max-box">
                                        该地区有{{reg.max}}个小号可选
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="生效时间">
                            <div class="font-size-color">
                                立即生效
                            </div>
                        </el-form-item>
                        <el-form-item label="">
                            <div class="flex-nowrap-flex-end font-size-color">
                                预计1-2个工作日内完成审核，法定节假日顺延
                            </div>
                        </el-form-item>
                        <el-form-item label="">
                            <div class="flex-nowrap-flex-end">
                                <el-button size="medium" class="button-left" @click="numbeArssignedDialogVisible = false">取消</el-button>
                                <el-button size="medium" class="button-right" @click="addNumbs">立即申请</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { IconFont } from "@/utils/iconfont"
import lineCard from "./lineComponent/lineCard.vue"//分页item
import amount from '@/api/open/amount/amount'
import number from '@/api/open/privacy/number'
import numberOrders from '@/api/open/privacy/numberOrders'
import dictionary from "@/api/system/dictionary"
export default {
    name:"SetLine",
    components:{
        IconFont,
        lineCard,
    },
    data(){
        return{
            type: 1,
            searchForm:{
                page:1,
                pageSize:52,
                mode:'0',
                "sortBy":[
                    "createTime"
                ],
                "sortDesc":[
                    "false"
                ]
            },
            lineList:[],
            linePopupType:'set',
            modemaap:{},
            numberCountList:[],
            total:0,
            loading:true,
            centerDialogVisible:false,
            numbeArssignedDialogVisible:false,
            amountId:null,
            // 弹窗
            html:'',
            optionsList:[],
            form:{
                amountId:null,
                amountName:'',
                interfaceId:null,
                type:null,
                expenses:null,
                region:[
                    {
                        name:null,
                        num:null,
                        min:0,
                        max:0
                    }
                ]
            },
            rules: {
                expenses:[
                    {
                        required: true,
                        message: '资费不能为空',
                        trigger: 'blur',
                    }
                ],
                num:[
                    {
                        required: true,
                        message: '小号地区不能为空',
                        trigger: 'blur',
                    }
                ],
            },
        }
    },
    mounted(){
        this.getModeList()
        this.getAmounttsList()
        // 监听滚动事件
        this.divScrollChange()
    },
    methods:{ 
        getAmounttsList(){
            this.loading = true
            amount.amountList({ ...this.searchForm }).then((res) => {
                this.lineList = res.data.records
                this.total = res.data.total
                this.loading = false
            });
        },
        handleChange(val,r) {
            this.numberCountList.forEach((citys,cs)=>{
                citys.citys.forEach((city,c)=>{
                    if(city.city == val[val.length-1]){
                        this.form.region[r].max = city.count
                    }
                })
            })
            console.log(r);

        },
        handleCurrentChange(val){
            this.searchForm.page = val
            this.getAmounttsList()
        },
        setTimeData(){
            this.getAmounttsList()
        },
        // 获取线路模块表
        getModeList(){
            dictionary.queryName({
                type:'mode'
            }).then(res=>{
                res.data.forEach((mode,m) => {
                    this.modemaap[mode.code] = mode.remarks
                });
            })
        },
        // 下拉加载更多
        divScrollChange(){
            let scroll = document.getElementById('scroll'),
                scrollTop = this.$refs.scrollTopList.scrollTop, 
                clientHeight = this.$refs.scrollTopList.clientHeight, 
                scrollHeight = this.$refs.scrollTopList.scrollHeight,
                height = this.$refs.scrollTopList.offsetHeight;
            let that = this;
            scroll.addEventListener('scroll',function(e){
                if(scrollTop + clientHeight >= scrollHeight - height){
                    if(that.searchForm.pageSize > that.total){
                        return false
                    }else{
                        that.searchForm.pageSize = that.searchForm.pageSize + 8
                        that.getAmounttsList()
                    }  
                }else{
                    return false
                }
            })
        },
        // 查看服务商信息
        checkView(data){
            this.html = data.description
            this.centerDialogVisible = true
        },
        // 申请小号分配
        numbeArssigned(data){
            this.getAvailable(data.id)
            this.form.amountId = data.id
            this.form.amountName = data.name
            this.form.interfaceId = data.interfaceId
            this.form.mode = data.mode
            let newLists = JSON.parse(data.options)
            let listOther = [];
            newLists.forEach((option,o)=>{
                listOther.push({
                    name:(option.final==''?0:option.final)+'元/分钟'+this.pase(option.surcharge)
                })
            })
            this.optionsList = listOther
            this.numbeArssignedDialogVisible = true
        },
        getAvailable(amountId){
            // number.available({
            //     amountId:amountId
            // }).then(res=>{

            // })
            number.numberCount({
                amountId:amountId
            }).then(res=>{
                this.numberCountList = res.data
            })
        },
        pase(obj){
            let value = ''
            obj.forEach((sobj,s) => {
                value = sobj.value + value
            });
            if(value == ''){
                return "(无录音)"
            }else{
                return "(含"+value+")"
            }
            
        },
        addItem(){
            this.form.region.push({
                name:null,
                num:null,
                min:0,
                max:10
            })
        },
        dleItem(o){
            if(o != 0){
                this.form.region.splice(o,1)
            }
        },
        // 申请
        addNumbs(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let numberEnd = null;
                    this.form.region.forEach((reg,r)=>{
                         numberEnd = numberEnd + reg.num
                    })
                    this.form.needs = numberEnd
                    numberOrders.add({
                        amountId:this.form.amountId,
                        mode:this.form.mode,
                        expenses:this.form.expenses,
                        needs:this.form.needs,
                        region:JSON.stringify(this.form.region)
                    }).then(res=>{
                        this.$notify({
                            title: '成功',
                            message: '小号申请成功！',
                            type: 'success'
                        });
                        this.numbeArssignedDialogVisible = false
                    })
                } else {
                console.log('error submit!!')
                return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .SetLine{
        padding-right: 1.125rem;
    }
    .lineCard-show-box{
        padding-top: 20px;
        height: calc(100vh - 250px);
        overflow-y: overlay;
    }
    .type-name{
        width: 80px;
    }
    .line-in-button{
        width: 100%;
    }
    .button-width{
        width: 100%;
    }
    .button-left{
        color: #333333;
        background: #f2f3f8;
    }
    .button-right{
        color: #FFFFFF;
        background: #637dff;
    }
    .button-right-other{
        color: #FFFFFF;
        background: #E5E5E5;
    }
    // 小号申请
    .buy-notice{
        padding: 6px 17px;
        background: #f6f6f6;
        margin-bottom: 15px;
    }
    .font-size-color{
        font-size: 12px;
        font-weight: 400;
        color: #333333;
    }
    .select-out-box{
        .el-select{
            width: 80%;
        }
    }
    .select-out-box-other{
        width: 140px;
    }
    .add-text{
        font-size: 12px;
        font-weight: 400;
        color: #3355ff;
        cursor: pointer;
    }
    .dle-text{
        font-size: 12px;
        font-weight: 400;
        color: #F56C6C;
        cursor: pointer;
    }
    .max-box{
        font-size: 12px;
        font-weight: 400;
        color: #909399;
    }
    .out-num-box{
        width: 100%;
        height: 160px;
        overflow: overlay;
    }
    .show-html{
        max-height: 500px;
        overflow-y: overlay;
        border-radius: 6px;
        padding: 10px 16px;
        background: #fff;
    }
</style>